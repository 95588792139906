/*
 * @Descripttion: 
 * @version: 
 * @Author: yh
 * @Date: 2023-04-03 16:21:06
 * @LastEditors: yh
 * @LastEditTime: 2023-04-28 14:49:00
 */
// dev test preprod prod
let NODE_ENV = 'prod'

export default NODE_ENV