import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/global.scss";

import "./assets/icon/iconfont.css";

import * as uni from "@dcloudio/uni-webview-js";

document.addEventListener("UniAppJSBridgeReady", function () {
  Vue.prototype.$uni = uni;
});

import env from "../../node-env.js";
import TzscheCls from "./assets/js/tzsche";
Vue.prototype.$tzsche = TzscheCls;

Vue.config.productionTip = false;

import Amplify, { Auth } from "aws-amplify";

var Auth_name = {};

if (env == "dev") {
  Auth_name = {
    userPoolWebClientId: "6rmsjlr4ctfroiokmd3ib8mquq",
    region: "us-east-1",
    userPoolId: "us-east-1_bxJkai0nx",
  };
} else if (env == "test") {
  Auth_name = {
    userPoolWebClientId: "6vn0ebhee45c70fcrum3t30ntp",
    region: "us-east-2",
    userPoolId: "us-east-2_Jt4EA8MAx",
  };
} else if (env == "preprod") {
  Auth_name = {
    userPoolWebClientId: "6p45h2bhbjfvoe63milqrcki87",
    region: "us-east-2",
    userPoolId: "us-east-2_50DgiSTje",
  };
} else if (env == "prod") {
  Auth_name = {
    userPoolWebClientId: "72ce79mjdl98mmdcv5ipis2elh",
    region: "us-east-1",
    userPoolId: "us-east-1_Tqt7PklGj",
  };
}

Amplify.configure({
  //dev
  // Auth: {
  //   identityPoolId: 'us-east-1:74d05107-fc90-4ef3-b4eb-88d2efafd399',
  //   userPoolWebClientId: '7ncuu9io8du2s46d0gbajnjftj',
  //   region: 'us-east-1',
  //   userPoolId: 'us-east-1_RF2nBYZyx',
  // }
  // Auth: {
  //  userPoolWebClientId: '5bitph11kkph29qc423orpce1v',
  //  region: 'us-east-1',
  //  userPoolId: 'us-east-1_JSLotU2Yh'
  // }
  Auth: Auth_name,
});

Auth.configure();

import { Uploader } from "vant";

Vue.use(Uploader);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
