import axios from "axios"
class TzscheCls {
	constructor() {
	}
	isTid() {
		let date = new Date();
		return date.getTime()
	}
	httpPost(option) {
		return new Promise((resolve, reject) => {
			axios({
				method: option.method,
				url: option.url,
				data: option.data || {},
				headers: option.header || {},
			}).then(function (response) {
				if(response.status==200){
					resolve(response.data);
				}
			}).catch(function (error) {
				reject(error)
			});
		})
	}
	setInterval(fn, times, total) {
		function interval() {
			if (typeof total === "undefined" || total-- > 0) {
				setTimeout(interval, times);
				try {
					fn()
				} catch (e) {
					total = 0;
					throw e.toString();
				}
			}
		}
		setTimeout(interval, times)
	}
	isUpdate(url, parameter, fileName) {
		let contentType = "";
		if (fileName) {
			let fileNameArr = fileName.split(".")
			let types = {
				"jpg": "image/jpeg",
				"tiff": "image/tiff",
				"gif": "image/gif",
				"jfif": "image/jpeg",
				"png": "image/png",
				"tif": "image/tiff",
				"ico": "image/x-icon",
				"jpeg": "image/jpeg",
				"wbmp": "image/vnd.wap.wbmp",
				"fax": "image/fax",
				"net": "image/pnetvue",
				"jpe": "image/jpeg",
				"rp": "image/vnd.rn-realpix"
			}
			contentType = types[fileNameArr[1]]
		}
		return new Promise((resolve, reject) => {
			axios({
				method: 'put',
				url: url,
				data: parameter,
				headers: {
					'Content-Type': contentType || "image/png"
				}
			}).then(function (response) {
				console.log("response",response)
				resolve(response);
			}).catch(function (error) {
				reject(error)
			});
		})

	}



}
let Tzsche = new TzscheCls();
export default Tzsche;
