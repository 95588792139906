import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: () => import('../views/Login/SignIn.vue')
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: () => import('../views/Login/SignUp.vue')
  },
  {
    path: '/Code',
    name: 'Code',
    component: () => import('../views/Login/Code.vue')
  },
  {
    path: '/Forget',
    name: 'Forget',
    component: () => import('../views/Login/Forget.vue')
  },
  {
    path: '/Update',
    name: 'Update',
    component: () => import('../views/Update/index.vue')
  },
  {
    path: '/Camera',
    name: 'camera',
    component: () => import('../views/Update/camera.vue')
  },
  {
    path: '/File',
    name: 'File',
    component: () => import('../views/Update/file.vue')
  },
  {
    path: '/Authentication',
    name: 'Authentication',
    component: () => import('../views/Login/Authentication.vue')
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
